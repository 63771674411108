import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import GameBoard from "canvas-pong/src/lib/game-board";
import Canvas from "canvas-pong/src/lib/ui/canvas";
import AudioControls from "canvas-pong/src/lib/ui/audio-control";
import GameSettings from "canvas-pong/src/lib/game-settings";
import { useIframePostMessage } from "../../../utils/hooks/post-message";

const CanvasApp = styled.div``;

export const CanvasPongPage = () => {
  let width = 1280;
  let height = 720;

  if (typeof window !== `undefined`) {
    width = window.innerWidth;
  }

  if (typeof document !== `undefined`) {
    height = document.body.getBoundingClientRect().height;
  }

  const [resizeMessage, setResizeMessage] = useState({
    description: "window resize",
    width,
    height,
  });

  if (typeof window !== `undefined`) {
    useIframePostMessage(resizeMessage, window.parent);
  }

  const handleResize = () => {
    if (typeof document !== `undefined`) {
      const bodyRect = document.body.getBoundingClientRect();
      const { height } = bodyRect;
      setResizeMessage({
        ...resizeMessage,
        height,
      });
    }
  };

  const appRef = useRef() as React.MutableRefObject<HTMLElement>;
  const [appLoaded, setAppLoaded] = useState(false);

  const main = (app: HTMLElement) => {
    if (appLoaded) return;

    if (typeof document === `undefined`) {
      return;
    }

    if (!app) return;

    const canvas = new Canvas();
    const canvasElement = canvas.getElement();
    app.appendChild(canvasElement);

    const audioControls = new AudioControls();
    const audioControlElement = audioControls.getElement();
    app.appendChild(audioControlElement);
    GameSettings.buildAudioControl("sound");

    const gameBoard: GameBoard = new GameBoard(canvasElement);

    gameBoard.loop(null);
    handleResize();
    setAppLoaded(true);
  };

  useEffect(() => {
    if (appRef.current) {
      main((appRef.current as unknown) as HTMLElement);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <CanvasApp
      ref={appRef as React.MutableRefObject<HTMLElement>}
      id="canvas-pong-app"
    />
  );
};

export default CanvasPongPage;
