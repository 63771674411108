import { useEffect } from "react";

export const useIframeHandleMessage = (
  handler: (e: MessageEvent) => void,
  frame: HTMLIFrameElement
) => {
  let target = "";
  if (typeof window !== `undefined`) {
    target = `${window.location.protocol}//${window.location.host}`;
  }
  const handlerMiddleware = (event: MessageEvent) => {
    if (event.origin !== target) return;
    if (frame && frame.contentWindow === event.source) {
      handler(event);
    }
  };
  useEffect(() => {
    window.addEventListener("message", handlerMiddleware, false);
    return () => {
      window.removeEventListener("message", handlerMiddleware, false);
    };
  });
};

export const useIframePostMessage = (message: any, contentWindow: Window) => {
  const target = `${contentWindow.location.protocol}//${contentWindow.location.host}`;
  useEffect(() => {
    contentWindow.parent.postMessage(message, target);
    return () => {};
  });
};
